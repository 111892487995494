<template>
	<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
</template>

<script>
import AppBoxTheme from "@/components/ui/AppBoxTheme";

export default {
	name: "ToolsPage",

	data() {
		return {
			options: [
				{
					title: "app.meetings.meet_with_mentor.title",
					icon: "online-meeting",
					route: {
						name: "Meet",
					},
					fade: this.$environments.environment == "production",
				},
				{
					title: "app.my_documents",
					icon: "file",
					route: {
						name: "ToolsEditor",
					},
				},
				{
					title: "app.meetings_summary",
					icon: "analysis",
					route: {
						name: "MeetingsList",
					},
				},
				{
					title: "app.ko.title",
					icon: "idea",
					route: {
						name: "KnowledgeOrganizers",
					},
					fade: this.$environments.environment == "production",
				},
				{
					title: "app.interviews.title",
					icon: "job-interview",
					route: {
						name: "InterviewsList",
					},
				},
				{
					title: "app.tools.set_priority.title",
					icon: "prioritize",
					route: {
						name: "SetPriority",
					},
				},
				{
					title: "app.tools.standard_mind_map.title",
					icon: "mindmap",
					route: {
						name: "StandardMindmapList",
					},
				},
				{
					title: "app.tools.wizard_mind_map.title",
					icon: "step",
					route: {
						name: "ToolsWizardMindmap",
					},
					fade: this.$environments.environment == "production",
				},
				{
					title: "app.question_creator",
					icon: "question-mark",
					route: {
						name: "QuestionCreator",
					},
					fade: this.$environments.environment == "production",
				},
				{
					title: "app.observations.title",
					icon: "observation",
					route: {
						name: "ObservationsList",
					},
					fade: true,
				},
				{
					title: "app.experiments.title",
					icon: "experiment",
					route: null,
					fade: true,
				},
			],
		};
	},

	components: {
		AppBoxTheme,
	},

	methods: {
		async proceed(route) {
			try {
				this.$loader.start();

				if (!route) {
					return;
				}

				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
